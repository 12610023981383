import(/* webpackMode: "eager" */ "/var/www/ptm/dev/monorepo/dev.pay2me.co/node_modules/.pnpm/next@15.2.1_@babel+core@7.26.0_@playwright+test@1.48.2_babel-plugin-macros@3.1.0_react-_43d18447705cf5b850c22a90c432d65e/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/var/www/ptm/dev/monorepo/dev.pay2me.co/node_modules/.pnpm/next@15.2.1_@babel+core@7.26.0_@playwright+test@1.48.2_babel-plugin-macros@3.1.0_react-_43d18447705cf5b850c22a90c432d65e/node_modules/next/dist/client/components/client-segment.js");
;
import(/* webpackMode: "eager" */ "/var/www/ptm/dev/monorepo/dev.pay2me.co/node_modules/.pnpm/next@15.2.1_@babel+core@7.26.0_@playwright+test@1.48.2_babel-plugin-macros@3.1.0_react-_43d18447705cf5b850c22a90c432d65e/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/var/www/ptm/dev/monorepo/dev.pay2me.co/node_modules/.pnpm/next@15.2.1_@babel+core@7.26.0_@playwright+test@1.48.2_babel-plugin-macros@3.1.0_react-_43d18447705cf5b850c22a90c432d65e/node_modules/next/dist/client/components/http-access-fallback/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/var/www/ptm/dev/monorepo/dev.pay2me.co/node_modules/.pnpm/next@15.2.1_@babel+core@7.26.0_@playwright+test@1.48.2_babel-plugin-macros@3.1.0_react-_43d18447705cf5b850c22a90c432d65e/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/var/www/ptm/dev/monorepo/dev.pay2me.co/node_modules/.pnpm/next@15.2.1_@babel+core@7.26.0_@playwright+test@1.48.2_babel-plugin-macros@3.1.0_react-_43d18447705cf5b850c22a90c432d65e/node_modules/next/dist/client/components/metadata/async-metadata.js");
;
import(/* webpackMode: "eager" */ "/var/www/ptm/dev/monorepo/dev.pay2me.co/node_modules/.pnpm/next@15.2.1_@babel+core@7.26.0_@playwright+test@1.48.2_babel-plugin-macros@3.1.0_react-_43d18447705cf5b850c22a90c432d65e/node_modules/next/dist/client/components/metadata/metadata-boundary.js");
;
import(/* webpackMode: "eager" */ "/var/www/ptm/dev/monorepo/dev.pay2me.co/node_modules/.pnpm/next@15.2.1_@babel+core@7.26.0_@playwright+test@1.48.2_babel-plugin-macros@3.1.0_react-_43d18447705cf5b850c22a90c432d65e/node_modules/next/dist/client/components/render-from-template-context.js");
